<template>
  <div>
    <div class="header main">
      <div class="header_left">
        <img height="100%" src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="header_right d-none d-sm-block">
        <el-menu
          router
          :default-active="$route.path"
          active-text-color="#fff"
          text-color="#fff"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item index="/homepage">首页</el-menu-item>
          <el-menu-item index="/productLine">产品线</el-menu-item>
          <el-menu-item index="/application">应用软件</el-menu-item>
          <el-menu-item index="/industry">行业方案</el-menu-item>
          <el-menu-item index="/my">联系我们</el-menu-item>
        </el-menu>
      </div>
      <!-- 弹框 -->
      <div class="header_right d-sm-none">
        <div class="dropdown">
          <div id="dropdownMenuButton1" data-bs-toggle="dropdown">
            <img src="@/assets/image/Banner/c6.png" alt="" />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <!-- <li class="dropdown_li"></li> -->
            <el-menu
              router
              :default-active="$route.path"
              active-text-color="##0D36CE"
              text-color="##333333 "
              class="el-menu-demo"
            >
              <el-menu-item index="/homepage">首页</el-menu-item>
              <el-menu-item index="/productLine">产品线</el-menu-item>
              <el-menu-item index="/application">应用软件</el-menu-item>
              <el-menu-item index="/industry">行业方案</el-menu-item>
              <el-menu-item index="/my">联系我们</el-menu-item>
            </el-menu>
          </ul>
        </div>
      </div>
    </div>
    <!-- 轮播start -->
    <div class="banner d-none d-sm-block">
      <el-carousel height="600px">
        <el-carousel-item>
          <div class="banner_add main">
           <div class="banner_addpc_tit">智能储存解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>支持存储介质:Flash、SSD、硬盘</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi、以太网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持远程传输:云+互联网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持产品形态:网络u盘,网络S5D、网络硬盘、NAS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP : Android APP、iOS APP、Windows APP、Mac APP、Linux
                  APP
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z4.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">手机连线解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>  支持连接设备:U盘、硬盘、SSD、摄像头</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式: USB传输线</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit"> 支持连接接口: Micro USB、Type-C、Lighting</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持手机系统: Android、iOS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
               支持访问APP: Andraid APP、iOS APP
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">智能家居解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>支持家居设备:智能传感器/摄像头/门窗/开关/窗帘/小电家等</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi、Zgbee、NB-loT、蓝牙</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持云平台:亚马逊、涂鸦等</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持访问终端:Andraid、iOS、浏览器
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持访问APP:Andraid APP、iOS APP、WEB
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z3.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">AI设备周边解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div> 智能传感模块:火、烟、光、温度、湿度、雨、水压、血压、声者、活动、</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div><img src="" alt="" /></div>
                <div style="margin-left: 26px">   时间、物体、CO2等传感器</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">智能手势模块:支持AI设备的手势操作</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">  智能传输模块:WiFi、Zigbee、NB-loT、蓝牙</div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z2.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="banner d-sm-none">
      <el-carousel height="274px">
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>智能储存解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持存储介质:Flash.SSD、硬盘</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi、以太网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持远程传输:云+互联网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持产品形态:网络u盘,网络S5D、网络硬盘、NAS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP : Android APP、iOS APP、Windows APP,Mac APP、Linux
                  APP
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>手机连线解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接设备:U盘、硬盘、SSD、摄像头
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式: USB传输线</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接接口: Micro USB、Type-C、Lighting
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持手机系统: Android. iOS</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP: Andraid APP、iOS APP
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c2.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>智能家居解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持家居设备:智能传感器/摄像头/门窗/开关/窗帘/小电家等
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接方式:WiFi Zgbee、NB-loT、蓝牙
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持云平台:亚马逊、涂鸦等</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持访问终端:Andraid、iOS、浏览器</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP:Andraid APP、iOS APP、WEB
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c4.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>AI设备周边解决方案</div>
            <div style="margin-top: 12px">
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  智能传感模块:火、烟、光、温度、湿度、雨、水压、血压、声者、活动、
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="add_ph_tit" style="margin-left: 15px">
                  时间、物体、CO2等传感器
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">智能手势模块:支持AI设备的手势操作</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  智能传输模块:WiFi、Zigbee、NB-loT、蓝牙
                </div>
              </div>
            </div>
          </div>

          <img class="ba_im" src="@/assets/image/Banner/c3.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播end -->

    <!-- footer start -->
    <div class="footer d-none d-sm-block">
      <div class="main">
        <div class="main_one">
          <img src="@/assets/image/logo.png" alt="" />
          <div class="footer_tit">爱四季</div>
        </div>
        <div class="footer_main">
          <div class="footer-lin1">
            <div class="footer_min_tit">期待为您服务</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (3).png" alt="" />
              </div>
              <div>近十年解决方案经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (3).png" alt="" />
              </div>
              <div>专注的领域经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (3).png" alt="" />
              </div>
              <div>近50人研发经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (3).png" alt="" />
              </div>
              <div>全球大客户服务经验</div>
            </div>
            <div></div>
          </div>
          <div class="footer-lin2">
            <div class="footer_min_tit">联系我们</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (1).png" alt="" />
              </div>
              <div>电话 : (+86)-xxxx-85582181</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (4).png" alt="" />
              </div>
              <div>邮件 : support@i4season.com</div>
            </div>
            <div>
              <div class="mg_bom d-flex">
                <div class="mg_ri">
                  <img src="@/assets/image/g (4).png" alt="" />
                </div>
                <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长沙市高新技术开发区麓谷企业广场F1栋24楼
              </div>
            </div>
          </div>
          <div class="footer-lin3">
            <div class="footer_min_tit">
              业务经理
              <div class="lin_ft"></div>
            </div>

            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (2).png" alt="" />
              </div>
              <div>钱经理 : 158 9236 8127</div>
            </div>
            <div>
              <img src="@/assets/image/wechat.png" alt="" />
            </div>
          </div>
        </div>
        <div class="footer-ft">
          © 2017-2021 四季宏胜科技有限公司 版权所有 | <a class="href" href="https://beian.miit.gov.cn/">粤ICP备14055127号-2</a>
        </div>
      </div>
    </div>

    <div class="footer d-sm-none">
      <div class="">
        <div class="footer-lin3 d-flex">
          <div>
            <div class="footer_min_tit add_footer_min_tit">业务经理</div>

            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (2).png" alt="" />
              </div>
              <div>钱经理 : 158 9236 8127</div>
            </div>
          </div>
          <div>
            <img src="@/assets/image/wechat.png" alt="" />
          </div>
        </div>
        <div class="">
          <div class="footer-lin1">
            <div class="footer_min_tit">期待为您服务</div>
            <div class="lin_ft"></div>
            <div class="d-flex">
              <div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/g (3).png" alt="" />
                  </div>
                  <div>近十年解决方案经验</div>
                </div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/g (3).png" alt="" />
                  </div>
                  <div>专注的领域经验</div>
                </div>
              </div>
              <div style="margin-left: 10px">
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/g (3).png" alt="" />
                  </div>
                  <div>近50人研发经验</div>
                </div>
                <div class="mg_bom d-flex">
                  <div class="mg_ri">
                    <img src="@/assets/image/g (3).png" alt="" />
                  </div>
                  <div>全球大客户服务经验</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div class="footer-lin2">
            <div class="footer_min_tit">联系我们</div>
            <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (1).png" alt="" />
              </div>
              <div>电话 : (+86)-xxxx-85582181</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (4).png" alt="" />
              </div>
              <div>邮件 : support@i4season.com</div>
            </div>
            <div>
              <div class="mg_bom d-flex">
                <div class="mg_ri">
                  <img src="@/assets/image/g (5).png" alt="" />
                </div>
                <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长沙市高新技术开发区麓谷企业广场F1栋24楼
              </div>
            </div>
          </div>
        </div>
        <div class="footer-ft">
          <div class="cen">© 2017-2021 四季宏胜科技有限公司</div>
          <a href="https://beian.miit.gov.cn/" class="cen">版权所有 | 粤ICP备14055127号-2</a>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
// header start
.href{
  color: #000;
  text-decoration: none;
}
.el-menu {
  background: rgba($color: #000000, $alpha: 0);
}
/deep/.el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: rgba($color: #000000, $alpha: 0) !important;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  // border-bottom:2px solid #fff ;
}
.header_left {
  position: absolute;
  top: 16px;
}
.header_right {
  position: relative;
  left: 66%;
  top: 16px;
}
.header_top_lin {
  z-index: 99;
  width: calc(100vw);
  position: absolute;
  top: 100%;
  left: -30%;
  border-bottom: 1px solid rgba(252, 252, 252, 0.3);
}
// header end

// 轮播start
.banner {
  max-width: 1920px;
  margin: 0 auto;
}
.ba_im {
  max-width: 1920px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.el-carousel__indicators--horizontal {
  display: none;
}
//add
.banner_add {
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translate(-50%);
  top: 32%;
   letter-spacing: 4px;
  color: #fff;
}
.banner_addpc_tit{
  font-size: 56px;
}
.box_add_ph_tit{
  font-size: 16px;
  height: 34px;
   letter-spacing: -1px;
}
.boximg_add{
  margin-right: 10px;
}


// 轮播end

// footer start
.footer {
  background: #fff;
  color: #000;
  font-family: PingFangSC-Medium;
  padding-bottom: 24px;
}
.main_one {
  display: flex;
  align-items: center;
}
.footer_main {
  display: flex;
  justify-content: space-between;
}
.footer_tit {
  color: #fff;
  font-weight: 200;
  font-family: FZLTXHK--GBK1-0;
  letter-spacing: 2px;
  font-size: 24px;
}
.footer_min_tit {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  // border-bottom:2px solid #fff ;
}
.lin_ft {
  width: 80px;
  border-bottom: 4px solid #0371f1;
  margin: 24px 0;
}
.mg_bom {
  margin-bottom: 12px;
}
.footer-ft {
  margin-top: 10px;
}
.mg_ri {
  margin-right: 8px;
  margin-top: -2px;
}
// footer end
// 手机
@media only screen and (max-width: 760px) {
  // tou
  .header {
    width: calc(100vw);
    display: flex;
    justify-content: space-between;
  }
  .header_left {
    height: 28px;
    margin-left: 18px;
    margin-top: -2px;
  }
  .header_right {
    margin-left: 92px;
  }
  .dropdown {
    margin-bottom: 20px;
  }
  .dropdown-menu {
    width: calc(100vw);
    height: calc(100vh);
    background: #fff;
  }
  // banner
  .banner {
    width: calc(100vw);
  }
  .ba_im {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  //footer
  .footer {
    width: calc(100vw);
    padding: 0 20px;
  }
  .footer-lin3 {
    justify-content: space-between;
  }
  .footer-lin1 {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .footer-lin2 {
    font-size: 12px;
  }
  .footer-lin3 {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .lin_ft {
    border-bottom: 2px solid #fff;
  }
  .footer-ft {
    padding: 26px 0;
  }
  .cen {
    color: #000;
    text-decoration: none;
      text-align: center;
      display: inline-block;
  }
  .footer_min_tit {
    font-size: 16px;
  }
  .add_footer_min_tit {
    margin-bottom: 16px;
    margin-top: 6px;
  }
  .lin_ft {
    border-bottom: 2px solid #4066f5;
    margin: 10px 0 10px 0;
  }
  .footer-ft {
    font-size: 12px;
  }
  .banner_add_ph {
  position: absolute;
  z-index: 999;
  left: 5%;
  top: 38%;
  color: #fff;
}
.add_ph_tit {
  font-size: 10px;
  transform: scale(0.6);
  transform-origin: 0 0;
  white-space: nowrap;
  margin-top: 9px;
}
.box_add_ph_tit {
  height: 18px;
}
}
</style>
